<template>
  <div class="col-md-12 reservation-container">
    <div
      class="row user-reservations-container"
      @click="onClick"
      :class="{
        'selected': $store.getters['reservation/activeReservation'] === itemDateReservation.id,
        'cancelled': itemDateReservation.status === status.CANCELLED
      }"
    >
      <div class="col-md-2 p-0">
        <img
          v-if="itemDateReservation.socialArea.photoUrl"
          :src="itemDateReservation.socialArea.photoUrl"
          class="d-inline reservation-image"
        >
        <i
          v-else
          class="icon"
          :class="{ [itemDateReservation.socialArea.icon]: true }"
          style="font-size: 26px; margin-top: 13px; margin-left: 11px;"
        />
      </div>
      <div class="col-md-10 reservation-content" style="padding-right: 0">
        <i
          v-if="itemDateReservation.underMaintenance"
          class="tools icon under-maintenance-text"
          style="float: right"
        ></i>
        <p class="m-0 reservation-name">{{ itemDateReservation.user.firstName }} {{ itemDateReservation.user.lastName }}</p>
        <p class="m-0 reservation-space">{{ itemDateReservation.socialArea.name }}</p>
        <p class="m-0 reservation-time text-muted">
          {{ formatTime(itemDateReservation.reservationStart) }} - {{ formatTime(itemDateReservation.reservationEnd) }}
          <span
            v-if="itemDateReservation.status === status.CANCELLED"
            class="text-cancel"
          >{{ $t('reservation.cancelled').toUpperCase() }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/utils/helpers'

export default {
  name: 'UserReservationCard',
  props: {
    itemDateReservation: {
      type: Object,
      required: true
    },
    clicked: Function
  },
  methods: {
    formatTime (date) {
      return helper.get12HoursTime(new Date(date))
    },

    onClick () {
      if (this.itemDateReservation.status !== this.status.CANCELLED) {
        this.$emit('clicked', this.itemDateReservation)
      }
    }
  }
}
</script>

<style scoped>
  .user-reservations-container {
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0px 1px 3px #CCC;
    background-color: #F9F9F9;
    height: 57px;
    cursor: pointer;
  }

  .user-reservations-container:hover {
    opacity: 0.8;
  }

  .cancelled, .cancelled:hover {
    opacity: 0.5;
  }

  .reservation-container {
    margin: 0;
    padding-left: 10px;
    padding: 2px 10px 7px 10px;
    color: #4B4B4B
  }

  .reservation-image {
    border-radius: 6px 0px 0px 6px;
    width: 56px;
    height: 56px;
    object-fit: cover;
  }

  .reservation-content {
    padding-left: 19px;
    padding-top: 3px;
  }

  .reservation-name {
    font-size: 14px;
    line-height: 1;
    padding-top: 1px
  }

  .reservation-space {
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    padding-top: 2px;
  }

  .reservation-time {
    font-size: 11px;
    line-height: 1;
    padding-top: 2px
  }

  .reservation-buttons {
    line-height: 1;
    font-size: 10px;
    padding-top: 2px;
  }

  .selected {
    background-color: #E1EDF7;
    color: black;
    font-weight: bold;
  }

  .text-cancel {
    font-size: 9px;
    float: right;
    margin-top: 3px;
    margin-right: 3px;
    color: red
  }
</style>
