<template>
  <div>
    <h3 class="general-blue-color" style="font-size: 17px">
      {{ $t('reservation.reservationFor', { date: dateFormat }) }}
    </h3>

    <div class="row" style="margin-left: -5px; margin-right: 0px"
    >
      <UserReservationCard
        v-for="item in dateReservations"
        :key="item.id"
        :itemDateReservation="item"
        @clicked="selectReservation"
      />
    </div>

    <div
      v-if="dateReservations.length === 0"
      class="row"
    >
      <div class="col-md-12 text-center pt-4 px-5">
        <img src="@/assets/img/booking.png" style="width: 100px; height: 100px; opacity: 0.2">
        <p class="text-muted" style="font-size: 16px; opacity: 0.6">{{ $t('reservation.noReservationForThisDay') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import UserReservationCard from '@/views/Reservation/UserReservationCard'
import helper from '@/utils/helpers'

export default {
  name: 'ReservationsDay',
  components: {
    UserReservationCard
  },
  props: {
    date: String,
    clicked: Function
  },
  emits: ['clicked'],
  computed: {
    dateReservations () {
      const dateReservations = this.$store.getters['reservation/dateReservations']
      return dateReservations
    },
    dateFormat () {
      if (!this.date) {
        return ''
      }
      let date = this.date
      if (this.date.length <= 10) {
        date = date + 'T00:00:00'
      }
      const dateFormat = new Date(date)
      return helper.formatDateDDMMYY(dateFormat)
    }
  },
  methods: {
    selectReservation (reservation) {
      this.changeSelectedItem(reservation.id)
      this.$emit('clicked', reservation)
    },

    changeSelectedItem (itemId) {
      this.$store.dispatch('reservation/setActiveReservation', itemId)
    }
  }
}
</script>
