<template>
  <SuiModal basic v-model="dialog" style="overflow-y: hidden">
    <SuiModalContent style="overflow-y: hidden; height: 600px; display: flex !important; align-items: center">
        <div class="reservation_export_container">
            <div class="close_modal_x">
                <a @click="close()">
                    <i style="text-decoration:none !important; cursor: pointer;font-size: 25px"
                        class="times icon general-blue-color"
                    ></i>
                </a>
            </div>
            <SuiModalDescription>
                  <div class="description_container">
                    <form class="ui form fields">
                        <h1 style="align-self: flex-start; margin-left: -50px;">{{$t('buttons.exportReservations')}}</h1>
                        <!-- Range date container -->
                        <div class="range_date_container">
                            <div class="date">
                                <label style="font-size: 16px; color: #323232; margin-right: 20px">{{$t('reservation.startDate')}}</label>
                                <Datepicker
                                    name="initialDate"
                                    style="
                                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
                                    inputFormat='dd/MM/yyyy'
                                    :locale="getLanguage()"
                                    :typeable="false"
                                    v-model="form.initialDate"
                                />
                            </div>
                            <div class="date" style="justify-content: center">
                                <label style="font-size: 16px; color: #323232; margin-right: 20px">{{$t('reservation.endDate')}}</label>
                                <Datepicker
                                  name="finishDate"
                                    style="
                                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
                                    inputFormat='dd/MM/yyyy'
                                    :locale="getLanguage()"
                                    :typeable="false"
                                    v-model="form.finishDate"
                                />
                            </div>
                        </div>
                    </form>
                    <!-- Error messages -->
                    <div v-if="(v$.form.initialDate.$error || v$.form.finishDate.$error)">
                        <span class="text-danger" v-if="v$.form.initialDate.$error">
                            {{v$.form.initialDate.$errors[0].$message}}
                        </span>
                        <span class="text-danger" v-else-if="v$.form.finishDate.$error">
                            {{v$.form.finishDate.$errors[0].$message}}
                        </span>
                    </div>

                    <!-- Export button -->
                    <div class="btn_container">
                      <!-- <div> -->
                        <Button
                            :appearance="classBtnObject"
                            text="Exportar"
                            color="primary"
                            @clicked="exportReservations"
                        />
                      <!-- </div> -->
                    </div>
                  </div>
            </SuiModalDescription>
        </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import Datepicker from 'vue3-datepicker'
import { es, en } from 'date-fns/locale'
import i18n from '@/plugins/i18n'
import Button from '@/components/form/Button'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators/dist/raw.esm'
import { reservationReport } from '@/api/reservation'
import { mapActions } from 'vuex'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'ReservationExportModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Datepicker,
    Button
  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        initialDate: null,
        finishDate: null
      },
      excelReservationReportColumns: [
        this.$t('building.building'),
        this.$t('building.apartment'),
        this.$t('reservation.whoMadeReservation'),
        this.$t('reservation.area'),
        this.$t('reservation.date'),
        this.$t('reservation.time'),
        this.$t('reservation.dateOfCancellation')
      ].map(function (colName) {
        return {
          v: colName,
          t: 's',
          s: {
            font: {
              bold: true,
              color: {
                rgb: 'FFFFFF'
              }
            },
            fill: {
              fgColor: {
                rgb: '436F98'
              }
            },
            alignment: {
              vertical: 'center',
              horizontal: 'center'
            },
            border: {
              top: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              bottom: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              left: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              right: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              }
            }
          }
        }
      }),
      excelReservationReportColumnsActive: [
        this.$t('building.building'),
        this.$t('building.apartment'),
        this.$t('reservation.whoMadeReservation'),
        this.$t('reservation.area'),
        this.$t('reservation.date'),
        this.$t('reservation.time')
        // this.$t('reservation.dateOfCancellation')
      ].map(function (colName) {
        return {
          v: colName,
          t: 's',
          s: {
            font: {
              bold: true,
              color: {
                rgb: 'FFFFFF'
              }
            },
            fill: {
              fgColor: {
                rgb: '436F98'
              }
            },
            alignment: {
              vertical: 'center',
              horizontal: 'center'
            },
            border: {
              top: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              bottom: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              left: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              right: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              }
            }
          }
        }
      }),
      columnLettersActive: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      columnLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    }
  },
  validations () {
    return {
      form: {
        initialDate: {
          required: helpers.withMessage(this.$t('reservation.chooseStartDate'), (initialDateValue) => Boolean(initialDateValue))
        },
        finishDate: {
          required: helpers.withMessage(this.$t('reservation.chooseEndDate'), (finishDateValue) => Boolean(finishDateValue))
        }
      }
    }
  },
  computed: {
    dialog () {
      return this.show
    },
    es () {
      return es
    },
    en () {
      return en
    },
    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },
    residential () {
      return this.$store.getters['residential/activeResidential']
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    close () {
      this.form.initialDate = null
      this.form.finishDate = null
      this.$emit('close')
      this.v$.$reset()
    },
    getLanguage () {
      if (i18n.global.locale === 'en') {
        return en
      } else {
        return es
      }
    },
    getExcelFormattedStylishReservation (reservation, residentialName) {
      const reservationStartDate = new Date(reservation.reservationStart)
      const reservationEndDate = new Date(reservation.reservationEnd)
      // Formatting start and end hours
      // Start Hour
      let startHour = reservationStartDate.getHours()
      const startPeriod = startHour >= 12 ? 'PM' : 'AM'
      startHour = startHour > 12 ? startHour - 12 : (startHour === 0 ? 12 : startHour)
      let startMinutes = reservationStartDate.getMinutes()
      startMinutes = startMinutes < 10 ? `0${startMinutes}` : `${startMinutes}`
      const formattedStartHour = `${startHour}:${startMinutes} ${startPeriod}`

      // End Hour
      let endHour = reservationEndDate.getHours()
      const endHourPeriod = endHour >= 12 ? 'PM' : 'AM'
      endHour = endHour > 12 ? endHour - 12 : (endHour === 0 ? 12 : endHour)
      const formattedEndHour = `${endHour}:00 ${endHourPeriod}`

      let dateOfCancellation = '-'
      if (reservation.status === 'CANCELLED') {
        const dateObj = new Date(reservation.dateOfCancellation)
        const hours = dateObj.getHours()
        const period = hours >= 12 ? 'PM' : 'AM'
        const hour = hours > 12 ? hours - 12 : (hours === 0 ? 12 : hours)
        const minutes = dateObj.getMinutes()
        const min = minutes < 10 ? '0' + minutes : minutes
        const time = `${hour}:${min} ${period}`
        const date = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear()
        dateOfCancellation = date + ' ' + time
      }

      const cellStyleObject = {
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        },
        border: {
          top: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          bottom: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          left: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          right: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          }
        }
      }
      return [
        {
          // Building value for row
          v: residentialName,
          s: cellStyleObject
        },
        {
          // Apartment value for row
          v: reservation.apartment,
          s: cellStyleObject
        },
        {
          // UserName value for row
          v: reservation.userName,
          s: cellStyleObject
        },
        {
          // SocialArea name value for row
          v: reservation.socialAreaName,
          s: cellStyleObject
        },
        {
          // Reservation Date value for row
          v: `${reservationStartDate.getDate()}/${reservationStartDate.getMonth() + 1}/${reservationStartDate.getFullYear()}`,
          s: cellStyleObject
        },
        {
          // Reservation Hour value for row
          v: `${formattedStartHour} - ${formattedEndHour}`,
          s: cellStyleObject
        },
        {
          // Reservation cancelled hour value for row
          v: dateOfCancellation,
          s: cellStyleObject
        }
      ]
    },
    getExcelFormattedStylishReservationActive (reservation, residentialName) {
      const reservationStartDate = new Date(reservation.reservationStart)
      const reservationEndDate = new Date(reservation.reservationEnd)
      // Formatting start and end hours
      // Start Hour
      let startHour = reservationStartDate.getHours()
      const startPeriod = startHour >= 12 ? 'PM' : 'AM'
      startHour = startHour > 12 ? startHour - 12 : (startHour === 0 ? 12 : startHour)
      let startMinutes = reservationStartDate.getMinutes()
      startMinutes = startMinutes < 10 ? `0${startMinutes}` : `${startMinutes}`
      const formattedStartHour = `${startHour}:${startMinutes} ${startPeriod}`

      // End Hour
      let endHour = reservationEndDate.getHours()
      const endHourPeriod = endHour >= 12 ? 'PM' : 'AM'
      endHour = endHour > 12 ? endHour - 12 : (endHour === 0 ? 12 : endHour)
      const formattedEndHour = `${endHour}:00 ${endHourPeriod}`

      const cellStyleObject = {
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        },
        border: {
          top: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          bottom: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          left: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          right: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          }
        }
      }
      return [
        {
          // Building value for row
          v: residentialName,
          s: cellStyleObject
        },
        {
          // Apartment value for row
          v: reservation.apartment,
          s: cellStyleObject
        },
        {
          // UserName value for row
          v: reservation.userName,
          s: cellStyleObject
        },
        {
          // SocialArea name value for row
          v: reservation.socialAreaName,
          s: cellStyleObject
        },
        {
          // Reservation Date value for row
          v: `${reservationStartDate.getDate()}/${reservationStartDate.getMonth() + 1}/${reservationStartDate.getFullYear()}`,
          s: cellStyleObject
        },
        {
          // Reservation Hour value for row
          v: `${formattedStartHour} - ${formattedEndHour}`,
          s: cellStyleObject
        }
      ]
    },
    createResevationsReportExcelAndExportIt (reservationData) {
      // ------------------------------------------ Código bueno
      const residentialName = reservationData[0].building
      const activeReservations = reservationData
        .filter(reservation => reservation.status === 'ACTIVE')
        .map(reservation => this.getExcelFormattedStylishReservationActive(reservation, residentialName))

      const cancelledReservations = reservationData
        .filter(reservation => reservation.status === 'CANCELLED')
        .map(reservation => this.getExcelFormattedStylishReservation(reservation, residentialName))

      const reservationWorkBook = XLSX.utils.book_new()
      const filename = `${residentialName}_${this.$t('reservation.reservations')}`

      // Create worksheet with reservation data & Headers
      const colsFormattedActive = this.excelReservationReportColumnsActive
      const colsFormatted = this.excelReservationReportColumns

      // Big Building Name Header
      // const builidingBigHeader = [{
      //   v: `Edificio ${residentialName}`,
      //   s: {
      //     font: {
      //       bold: true,
      //       color: {
      //         rgb: 'FFFFFF'
      //       }
      //     },
      //     fill: {
      //       fgColor: {
      //         rgb: '436F98'
      //       }
      //     },
      //     alignment: {
      //       vertical: 'center',
      //       horizontal: 'center'
      //     },
      //     border: {
      //       top: {
      //         style: 'thin',
      //         color: {
      //           rgb: '000000'
      //         }
      //       },
      //       bottom: {
      //         style: 'thin',
      //         color: {
      //           rgb: '000000'
      //         }
      //       },
      //       left: {
      //         style: 'thin',
      //         color: {
      //           rgb: '000000'
      //         }
      //       },
      //       right: {
      //         style: 'thin',
      //         color: {
      //           rgb: '000000'
      //         }
      //       }
      //     }
      //   }
      // },
      // ...new Array(this.excelReservationReportColumns.length - 1).fill({
      //   v: ''
      // })
      // ]

      const activeData = [colsFormattedActive, ...activeReservations]
      const cancelledData = [colsFormatted, ...cancelledReservations]
      const activeReservationWorkSheet = XLSX.utils.aoa_to_sheet(activeData)
      const cancelledReservationWorkSheet = XLSX.utils.aoa_to_sheet(cancelledData)

      const activeMaxWidth = activeData.reduce((w, row) => Math.max(w, row.reduce((l, objValue) => Math.max(l, objValue.v.length), 10)), 10)
      const cancelledMaxWidth = cancelledData.reduce((w, row) => Math.max(w, row.reduce((l, objValue) => Math.max(l, objValue.v.length), 10)), 10)

      // Fix maximum width for every column
      activeReservationWorkSheet['!cols'] = this.columnLettersActive.map(function (colLetter) { return { wch: activeMaxWidth } })
      cancelledReservationWorkSheet['!cols'] = this.columnLetters.map(function (colLetter) { return { wch: cancelledMaxWidth } })

      // Merge for Big Building Name Header
      // reservationWorkSheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: colsFormatted.length - 1 } }]

      // Append worksheets to Workbook
      XLSX.utils.book_append_sheet(reservationWorkBook, activeReservationWorkSheet, this.$t('status.ACTIVES'))
      XLSX.utils.book_append_sheet(reservationWorkBook, cancelledReservationWorkSheet, this.$t('status.CANCELLED'))

      // Write Excel file to browser
      XLSX.writeFile(reservationWorkBook, `${filename}.xlsx`)
    },
    async exportReservations () {
      // SHOULD BE DONE WITH VUELIDATE
      if (this.form.initialDate && this.form.finishDate) {
        // VALIDATE RANGE (SHOULD BE DONE WITH VUELIDATE)
        if (
          this.form.initialDate.getTime() <= this.form.finishDate.getTime()
        ) {
          this.setLoading(true)

          const paramInitialDate = `${this.form.initialDate.getFullYear()}-${this.form.initialDate.getMonth() + 1}-${this.form.initialDate.getDate()}`
          const paramFinishDate = `${this.form.finishDate.getFullYear()}-${this.form.finishDate.getMonth() + 1}-${this.form.finishDate.getDate()}`

          const data = {
            residentialId: this.residential.residentialId,
            initDate: paramInitialDate,
            endDate: paramFinishDate
          }
          // console.log('data --->', data)
          reservationReport(data)
            .then((r) => {
              const reservationData = r.data
              // console.log('reservation received from backend --->', reservationData)
              if (reservationData.length === 0) {
                const spanishFormatInitialDate = `${this.form.initialDate.getDate()}/${this.form.initialDate.getMonth() + 1}/${this.form.initialDate.getFullYear()}`
                const spanishFormatFinishDate = `${this.form.finishDate.getDate()}/${this.form.finishDate.getMonth() + 1}/${this.form.initialDate.getFullYear()}`
                this.$swal(`${this.$t('reservation.noReservationsBetween')} ${spanishFormatInitialDate} ${this.$t('reservation.noReservationsBetween1')} ${spanishFormatFinishDate}`, '', 'info')
              } else {
                // console.log('data ->', reservationData)
                reservationData.forEach(res => {
                  res.reservationStart = res.reservationStart.replace('Z', '')
                  res.reservationEnd = res.reservationEnd.replace('Z', '')
                })
                // console.log('reservation transformed --->', reservationData)
                this.createResevationsReportExcelAndExportIt(reservationData)
                this.$swal(this.$t('reservation.successfulExport'), '', 'success')
                this.close()
              }
            }).catch((e) => {
              // console.log(e)
              this.$swal(this.$t('reservation.exportError'), '', 'error')
            }).finally(() => {
              this.setLoading(false)
            })
        } else {
          this.$swal(this.$t('reservation.startDateBefore'), '', 'error')
        }
      } else {
        this.$swal(this.$t('reservation.datesRequired'), '', 'error')
      }
    }
  }

}
</script>

<style scoped>
    h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      margin: 0;
      padding: 0;
      padding-left: 50px !important;
      max-width: inherit;
    }
    .reservation_export_container {
        background-color: #fff;
        width: 70%;
        height: 200px;
        border-radius: 20px;
    }
    .description_container{
        padding: 50px !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        height: 100%;
        margin-top: -15px !important;
    }
    .range_date_container {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .close_modal_x {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        padding-top: 15px;
    }
    .date {
        width: 50%;
        display: flex;
        align-items: center
    }
    .btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
    }
</style>
