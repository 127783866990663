<template>

  <div class="ui feed">
    <div class="event" :class="{ 'is-selected': active && active.uid === data.uid, 'unread': isSeen }"
      style="padding-left: 5px; border-radius: 7px" @click="openMessage">
      <div v-if="!isSent" class="label pt-2">
        <img :src="getAvatar(data.avatar)" style="width: 40px; height: 40px">
      </div>
      <div class="content" style="margin-left: 10px; position: relative">
        <i class="angle right icon" style="position: absolute; right: 0; top: 41%"></i>
        <div class="summary" style="font-size: 14px">
          <p v-if="isSent" class="mb-0">{{ truncateText(data.subject, itemMsgListSubjectMaxLengthToShow) }}</p>
          <p v-else-if="isChat" class="mb-0"><a href="#">{{ data.subject }}</a></p>
          <p v-else class="mb-0"><a href="#">{{ data.fullname }}</a></p>
          <div class="date" style="margin-left: 0px">
            {{ formatDate(data.lastMessage) }}
          </div>
        </div>
        <div class="extra text my-auto" style="color: rgba(0,0,0,.5); font-size: 14px; position: relative">
          {{ data.message ? truncateText(data.message, messageMaxLengthToShow) : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales'
import { mapGetters } from 'vuex'
import helpers from '../../utils/helpers'
import { markMessageAsSeen } from '../../api/messaging'
// import i18n from '@/plugins/i18n'

export default {
  name: 'MessageItem',
  props: {
    messageId: String,
    isSent: Boolean,
    isSeen: Boolean,
    isChat: Boolean
  },
  data () {
    return {
      itemMsgListSubjectMaxLengthToShow: 30,
      messageMaxLengthToShow: 100
    }
  },
  mixins: [{
    methods: {
      getAvatar: helpers.getAvatar,
      truncateText: helpers.truncateText
    }
  }],
  computed: {
    ...mapGetters('messaging', ['active', 'unread']),
    ...mapGetters('user', ['userMessageId']),
    ...mapGetters('user', ['residents']),
    data () {
      const messages = this.$store.getters['messaging/messages']
      const residents = this.residents
      const message = messages.find(msg => msg.uid === this.messageId)
      if (!message) {
        return {}
      }
      const user = residents.filter((resident) => { return resident.id === Number(message.participants[0]) })
      return {
        uid: message.uid,
        avatar: user.length > 0 ? user[0].avatar : message.lastMessage?.user?.avatar,
        fullname: user.length > 0 ? `${user[0].firstName} ${user[0].lastName}` : message.lastMessage?.user?.fullname,
        subject: message.subject,
        lastMessage: message.lastMessage?.createdAt,
        message: message.lastMessage?.message,
        unread: false
      }
    }
  },
  methods: {
    openMessage () {
      const messages = this.$store.getters['messaging/messages']
      this.$store.dispatch('messaging/getConversationDetail', this.messageId)
      this.$store.dispatch('messaging/setConversationActive', messages.find(msg => msg.uid === this.messageId))
      this.$store.dispatch('messaging/setResidentialMembersToMessage', [])
      this.markAsSeen()
      this.$router.push({ path: '/messaging/view' })
    },
    // setActive () {
    //   const sent = this.$store.getters['messaging/sent']
    //   const received = this.$store.getters['messaging/received']
    //   const messages = (this.isSent) ? sent : received
    //   this.$store.dispatch('messaging/setActive', messages.find(msg => msg.uid === this.messageId))
    //   this.markAsSeen()
    //   this.$router.push({ path: '/messaging/view' })
    // },

    markAsSeen () {
      markMessageAsSeen(this.messageId).then(() => { }).catch(console.log)
      // console.log('mark as seen', this.messageId)
      this.$store.dispatch('messaging/removeUnread', this.messageId)
    },

    formatDate (date) {
      moment.locale('es')
      if (date) {
        return moment(date).fromNow()
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.unread {
  background-color: rgb(241, 241, 241) !important;
  font-weight: bold;
  border-radius: 0px !important;
  border-left: 2px solid #dc3545 !important;
}

.row-message:hover,
.unread:hover,
.message-info:hover {
  color: #436F98 !important
}

.event {
  cursor: pointer;
}

.event:hover {
  background-color: #F2F2F2;
}

.is-selected {
  background-color: #E1EDF7 !important;
}

</style>
