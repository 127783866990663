<template>
  <FullCalendar
    class='demo-app-calendar'
    style="margin-right: 20px"
    :options="calendarOptions"
  >
    <!-- <template v-slot:eventContent='arg'>
      <b>{{ arg.timeText }}</b>
      <span>&nbsp;{{ arg.event.title }}</span>
    </template> -->
  </FullCalendar>

  <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 16px; margin-top: 5px">
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="ui mini under-maintenance-label label" style="height: 14px">&nbsp;</div>
        <span class="d-inline">{{ $t('reservation.maintenance') }}</span>
        <div class="ui mini red label prim-bg-color" style="height: 14px; margin-left: 10px">&nbsp;</div>
        <span class="d-inline">{{ $t('reservation.reserved') }}</span>
      </div>
    </div>
    <Button
      color="primary"
      :text="$t('buttons.exportReservations')"
      class="d-inline ml-1"
      @clicked="openReservationExportModal"
    />
  </div>

  <ReservationModal
    :show="visibleBookModal"
    :date="dateBookModal"
    :idReservationDefault="idReservationDefaultModal"
    @close="onCloseBookModal"/>

  <ReservationExportModal
    :show="isExportingReservationsModal"
    @close="closeReservationExportModal"
  />
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import Button from '@/components/form/Button'
import { mapActions, mapGetters } from 'vuex'
import { listReservations } from '@/api/reservation'
import ReservationModal from '@/components/modals/ReservationModal'
import ReservationExportModal from '@/components/modals/ReservationExportModal'
import i18n from '@/plugins/i18n'
// import helper from '@/utils/helpers'

export default {
  name: 'CalendarReservation',
  components: {
    FullCalendar,
    ReservationModal,
    ReservationExportModal,
    Button
  },
  data () {
    return {
      showBookModal: false,
      isExportingReservations: false,
      selectedDate: null,
      idReservationDefault: 0,
      eventsLoaded: {},
      events: [],
      calendarOptions: {
        height: 450,
        width: 300,
        locales: allLocales,
        locale: i18n.global.locale,
        allDaySlot: false,
        timeFormat: {
          '': 'h(:mm)t'
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin
        ],
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        initialView: 'listMonth',
        navLinks: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        events: [],
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        // datesSet: null,
        datesSet: this.handleMonthChange,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
        noEventsContent: this.noEventsContent
      }
    }
  },
  computed: {
    ...mapGetters('reservation', ['reservations']),

    ...mapGetters('residential', ['activeResidential']),

    ...mapGetters('loading', ['isLoading']),

    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    // eventList () {
    //   // const reservations = this.$store.getters['reservation/reservations']
    //   const reservations = this.$store.getters['residential/activeResidential']
    //   console.log('reservations>>>>>>>>>>>>', this.$store.getters['residential/activeResidential'])
    //   // this.addEvents(reservations)
    //   return reservations
    // },
    visibleBookModal () {
      return this.showBookModal
    },
    dateBookModal () {
      return this.selectedDate
    },
    idReservationDefaultModal () {
      return this.idReservationDefault
    },
    dataEvents () {
      return this.events
    },
    isExportingReservationsModal () {
      return this.isExportingReservations
    }
  },
  created () {
    this.$store.dispatch('reservation/setReservations', [])
  },
  watch: {
    reservations () {
      this.calendarOptions.events = this.reservations
    },
    activeResidential () {
      this.$store.dispatch('reservation/setReservations', [])
      this.getReservations()
      this.eventsLoaded = {}
    }
  },
  methods: {
    ...mapActions('reservation', ['addReservations']),

    ...mapActions('loading', ['setLoading']),

    noEventsContent () {
      return this.$t('reservation.noReservations')
    },
    getReservations () {
      this.setLoading(true)
      const datesUtil = Object.keys(this.eventsLoaded)[0].split('-')
      const initDate = datesUtil.slice(0, 3).join('-')
      const endDate = datesUtil.slice(-3).join('-')

      const events = []

      listReservations([
        { name: 'residentialId', value: this.activeResidential.residentialId },
        { name: 'initDate', value: initDate },
        { name: 'endDate', value: endDate }
      ]).then((r) => {
        const reservations = r.data
        reservations.forEach(res => {
          res.reservationStart = res.reservationStart.replace('Z', '')
          res.reservationEnd = res.reservationEnd.replace('Z', '')
        })
        reservations.forEach(x => {
          if (x.status === this.status.ACTIVE) {
            events.push({
              id: x.id,
              title: ` ${x.socialArea.name} - ${x.user.firstName} ${x.user.lastName} (${x.user.apartment})`,
              start: x.reservationStart,
              end: x.reservationEnd,
              classNames: (x.underMaintenance) ? ['under-maintenance-label'] : ['reservation-label']
            })
          }
        })
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.setLoading(false)
      })
    },

    async loadReservations (initDate, endDate) {
      const events = []
      try {
        const r = await listReservations([
          { name: 'residentialId', value: this.activeResidential.residentialId },
          { name: 'initDate', value: initDate },
          { name: 'endDate', value: endDate }
        ])

        const reservations = r.data

        reservations.forEach(res => {
          res.reservationStart = res.reservationStart.replace('Z', '')
          res.reservationEnd = res.reservationEnd.replace('Z', '')
        })
        reservations.forEach(x => {
          if (x.status === this.status.ACTIVE) {
            events.push({
              id: x.id,
              title: ` ${x.socialArea.name} - ${x.user.firstName} ${x.user.lastName} (${x.user.apartment})`,
              // start: x.reservationStart,
              start: x.reservationStart,
              end: x.reservationEnd,
              // end: x.reservationEnd,
              classNames: (x.underMaintenance) ? ['under-maintenance-label'] : ['reservation-label']
            })
          }
        })
        this.eventsLoaded[`${initDate}-${endDate}`] = events
      } catch (error) {}
      // console.log('Supuestas reservations (events) del edificio actual ', events)
      return events
    },

    handleWeekendsToggle () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    handleDateSelect (selectInfo) {
      this.showBookModal = true
      this.selectedDate = selectInfo.startStr
    },

    handleEventClick (clickInfo) {
      const id = Number(clickInfo.event.id)
      this.$store.dispatch('reservation/setActiveReservation', id)
      this.idReservationDefault = id
      this.showBookModal = true
      this.selectedDate = clickInfo.event.startStr
    },

    async handleMonthChange (payload) {
      const initDate = payload.startStr.substr(0, 10)
      const endDate = payload.endStr.substr(0, 10)
      if (this.eventsLoaded[`${initDate}-${endDate}`]) {
        return
      }

      const events = await this.loadReservations(initDate, endDate)
      this.addReservations([...events])
    },

    onCloseBookModal () {
      this.selectedDate = null
      this.idReservationDefault = 0
      this.showBookModal = false
    },

    openReservationExportModal () {
      this.isExportingReservations = true
    },

    closeReservationExportModal () {
      this.isExportingReservations = false
    }
  }
}
</script>

<style scoped>
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }

  .message-info {
    color: #6c757d;
    cursor: pointer;
  }
</style>
