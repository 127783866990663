<template>
  <div class="row">
    <div class="col-md-4 my-auto">
      <span class="title">{{  $t('messages.messages') }}</span>
    </div>
    <div class="col-md-8">
      <div class="d-flex g-2">
        <!-- <div class="col-md-6"> -->
          <button
            class="button-tab"
            :class="{ 'button-transparent': tab !== 'CHATS'}"
            @click="tab = 'CHATS'"
          >
            {{  $t('messages.chats') }}
            <!-- <div
              v-if="unread.length > 0"
              class="ui mini red label button-tab"
            >{{ unread.length }}</div> -->
          </button>
        <!-- </div> -->
        <!-- <div class="col-md-6"> -->
          <button
            class="button-tab"
            :class="{ 'button-transparent': tab !== 'RECEIVED'}"
            @click="tab = 'RECEIVED'"
          >
            {{$t('messages.received')}}
            <div
              v-if="unread.length > 0"
              class="ui mini red label"
            >{{ unread.length }}</div>
          </button>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="message-list">
    <div v-if="user.id">
      <div v-show="tab === 'CHATS'">
        <div v-if="chats.length > 0">
          <MessageItem
            v-for="item in chats"
            :key="item.uid"
            :messageId="item.uid"
            :isSent="false"
            :isSeen="false"
            :isChat="true"
          />
          <!-- <MessageItem
            v-for="item in messagesSent"
            :key="item.timestamp"
            :data="item"
            :isSent="true"
          /> -->
        </div>
        <div v-else>
          <div style="height: 400px; margin: auto; text-align: center">
            <img src="@/assets/img/chat.png" style="width: 100px; height: 100px; opacity: 0.2; margin-top: 100px">
            <!-- <p>No tienes ningún mensaje recibido</p> -->
            <p>{{  $t('messages.no_messages_sent') }}</p>
          </div>
        </div>
      </div>
      <div v-show="tab === 'RECEIVED'">
          <div v-if="messagesReceived.length > 0">
            <MessageItem
              v-for="item in messagesReceived"
              :key="item.uid"
              :messageId="item.uid"
              :isSent="false"
              :isSeen="checkSeen(item)"
              :isChat="false"
            />
            <!-- <MessageItem
              v-for="item in messagesReceived"
              :key="item.timestamp"
              :data="item"
              :isSent="false"
            /> -->
          </div>
        <div v-else>
          <div style="height: 400px; margin: auto; text-align: center">
            <img src="@/assets/img/chat.png" style="width: 100px; height: 100px; opacity: 0.2; margin-top: 100px">
            <!-- <p>No tienes ningún mensaje recibido</p> -->
            <p>{{  $t('messages.no_new_messages') }}</p>
          </div>
        </div>
      </div>

      <div v-show="tab === 'SENT'">
        <div v-if="messagesSent.length > 0">
          <MessageItem
            v-for="item in messagesSent"
            :key="item.uid"
            :messageId="item.uid"
            :isSent="true"
            :isSeen="false"
            :isChat="false"
          />
          <!-- <MessageItem
            v-for="item in messagesSent"
            :key="item.timestamp"
            :data="item"
            :isSent="true"
          /> -->
        </div>
        <div v-else>
          <div style="height: 400px; margin: auto; text-align: center">
            <img src="@/assets/img/chat.png" style="width: 100px; height: 100px; opacity: 0.2; margin-top: 100px">
            <!-- <p>No tienes ningún mensaje recibido</p> -->
            <p>{{  $t('messages.no_messages_sent') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-button" @click="newMessage">
      <i class="pencil alternate large icon inverted circular general-shadow fixed"></i>
    </div>
  </div>
</template>

<script>
import MessageItem from '@/views/Messaging/MessageItem'
import { mapGetters } from 'vuex'

export default {
  name: 'MessageList',
  components: { MessageItem },
  data () {
    return {
      // unread: [],
      tab: 'CHATS'
    }
  },
  computed: {
    ...mapGetters('messaging', ['messages', 'unread']),
    ...mapGetters('residential', ['activeResidential']),
    ...mapGetters('user', ['user']),

    messagesReceived () {
      const messages = this.$store.getters['messaging/messages']
      if (messages) {
        const unreadMessages = []
        messages.forEach(message => {
          // console.log('kmjkdcnvkev', message)
          if (message.lastMessage) {
            if (message.lastMessage?.user?._id !== this.user.id.toString() && message.lastMessage?.seen?.indexOf(this.user.id.toString()) === -1) {
              unreadMessages.push(message)
            }
          }
        })
        this.$store.commit('messaging/SET_UNREAD', unreadMessages)
        return messages.filter(message => !message.isDefaultGroup)
      } else {
        return []
      }
    },
    messagesSent () {
      const messages = this.$store.getters['messaging/messages']
      if (messages) {
        return messages.filter(message => message.lastMessage?.user?._id === this.user.id.toString())
      } else {
        return []
      }
    },
    chats () {
      const messages = this.$store.getters['messaging/messages']
      // console.log('jinvhijenvhjiddde', messages)
      if (messages) {
        return messages.filter(message => message.isDefaultGroup === true)
      } else {
        return []
      }
    }
  },
  created () {
    this.loadMessages()
  },
  watch: {
    activeResidential () {
      this.loadMessages()
    }
  },
  methods: {
    loadMessages () {
      this.$store.dispatch('messaging/loadMessages', { residentialId: this.activeResidential.residentialId, sessionUserId: this.user.id })
    },
    checkSeen (message) {
      return (message.lastMessage?.user?._id !== this.user.id.toString() && message.lastMessage?.seen?.indexOf(this.user.id.toString()) === -1)
    },
    newMessage () {
      this.$store.dispatch('messaging/setActive', null)
      this.$store.dispatch('messaging/setConversationActive', null)
      this.$router.push({ path: '/messaging' })
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 17px;
    font-weight: bold
  }

  .message-list {
    margin-right: 7px;
    margin-top: 20px;
    height: 480px;
    position: relative;
    overflow-y: auto;
  }

  .message-list:hover {
    overflow-y: auto;
  }

  .button-tab {
    width: 50%;
    min-width: 91.5px;
    position: relative;
    font-size: 14px;
    height: 35px;
    background-color: #00ccff;
    color: white;
    border: 0px;
    border-radius: 5px;
    padding: 5px 15px;
  }

  .button-tab:hover {
    opacity: 0.8;
  }

  .button-tab .label {
    position: absolute;
    right: -8px;
    top: -10px;
    height: 20px;
    padding: 5px 10px;
    width: auto;
  }

  .button-transparent {
    background-color: transparent;
    color: #000;
  }

  .button-transparent:hover, .floating-button:hover {
    opacity: 0.8;
  }

  .floating-button{
    position: absolute;
    bottom: 100px;
    right: 74px;
    color:#FFF;
    text-align:center;
    cursor: pointer;
  }
  .floating-button > i{
    background-color: var(--messaging-color) !important;
  }

  .g-2 {
    gap: 8px;
  }

</style>
