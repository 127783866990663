<template>
  <div class="justify-content-start view-container">
    <div v-if="localLoading" style="margin-left: -65px; margin-top: -17px;">
      <LoadLayout>
        <Loading/>
      </LoadLayout>
    </div>
    <HeaderResidential />
    <div style="height: 100px">
      <div :class="[{ 'blue double loading': isLoading }]" class="row mt-3" style="margin-right: 9px">
        <div class="col-md-3 messages-section">
          <MessageList />
        </div>
        <div
          class="col-md-8"
          style="padding-top: 10px; background-color: white; padding-left: 30px; padding-right: 15px"
        >
          <span class="title">{{ $t('reservation.reservations') }}</span>
          <div class="my-2"></div>
          <CalendarReservation />
        </div>
        <div class="col-md-1 demo-app-buttons text-center mt-4">
          <div v-for="button in shortcuts" :key="button.text">
            <a :href="button.link" v-if="button.isExternal" target="_blank">
              <div class="shortcut">
                <i class="icon large" :class="{ [button.icon]: true }" style="margin: 0px"></i>
                <p style="font-size: 12px; line-height: 14px;" class="mt-0">{{ button.text }}</p>
              </div>
            </a>
            <router-link :to="{ path: button.link }" v-else>
              <div class="shortcut">
                <i class="icon large" :class="{ [button.icon]: true }" style="margin: 0px"></i>
                <p style="font-size: 12px; line-height: 14px;" class="mt-0">{{ button.text }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderResidential from '@/components/ui/HeaderResidential'
import CalendarReservation from '@/views/Reservation/Calendar'
import MessageList from '@/views/Messaging/MessageList'
import LoadLayout from '@/layouts/LoadLayout'
import { mapActions } from 'vuex'

export default {
  components: {
    HeaderResidential,
    CalendarReservation,
    MessageList,
    LoadLayout
  },
  mounted () {
    this.loadTypes()
    // this.loadAreas()
    this.localLoading = true
    this.checkPQRS()
  },
  data () {
    return {
      shortcuts: this.returnShortcuts(),
      localLoading: false
    }
  },
  watch: {
    residential: {
      handler (val) {
        if (val) {
          // console.log('residential changed', val)
          this.loadTypes()
          this.localLoading = true
          this.checkPQRS()
        }
      },
      deep: true
    }
  },
  computed: {
    residential () {
      return this.$store.getters['residential/activeResidential']
    }
  },
  methods: {
    ...mapActions('types', [
      'loadTypes'
    ]),

    ...mapActions('pqrs', [
      'loadPQRS',
      'checkOpenPQRS'
    ]),
    ...mapActions('loading', ['setLoading']),
    // ...mapActions('socialAreas', [
    //   'load_Areas'
    // ]),
    // loadAreas () {
    //   this.load_Areas(this.residential.residentialId)
    // }
    goToPQRS () {
      this.$router.push('/pqrs')
    },
    returnShortcuts () {
      const PQRSlabel = this.$store.getters['residential/activeResidential'].PQRSlabel
      return [
        // { text: this.$t('shortcuts.pqrs'), icon: 'clipboard', link: '/pqrs', isExternal: false },
        { text: PQRSlabel, icon: 'clipboard', link: '/pqrs', isExternal: false }, // ? leerlo desde el store
        { text: this.$t('shortcuts.board'), icon: 'bullhorn', link: '/board', isExternal: false },
        // { text: 'Mensajes Masivos', icon: 'envelope outline', link: '/messaging/massive', isExternal: false },
        { text: this.$t('shortcuts.messages'), icon: 'envelope outline', link: '/messaging', isExternal: false },
        // { text: 'Mensajes Personalizados', icon: 'envelope outline', link: '/messaging/direct', isExternal: false },
        { text: this.$t('shortcuts.updateInfo'), icon: 'user', link: '/profile', isExternal: false },
        // { text: 'Directorio Proveedores', icon: 'address book outline', link: '/directory', isExternal: false },
        { text: this.$t('shortcuts.knowledge'), icon: 'brain', link: 'https://www.youtube.com/watch?v=Fbo_3RJo-nA&list=PLWpWJaWODB2nvuAnkpkOQuq7N1diAjni2', isExternal: true },
        { text: this.$t('shortcuts.digitalLibrary'), icon: 'download', link: '/digitalLibrary', isExternal: false }
      ]
    },
    checkPQRS () {
      const PQRSlabel = this.$store.getters['residential/activeResidential'].PQRSlabel

      this.checkOpenPQRS(this.$store.getters['residential/activeResidential'].residentialId).then(r => {
        console.log('r', r)
        console.log('isOpenPQRS', r.isOpenPQRS)
        if (r.isOpenPQRS) {
          this.setLoading(false)
          this.localLoading = false
          this.$swal({
            icon: 'warning',
            iconColor: '#dc3545',
            html: '' + this.$t('pqrsOpened', { PQRSlabel }) + ' ' + ' <span style="color: #1e88e5 !important; cursor: pointer !important;" id="goToPQRS">' + this.$t('pqrsOpened1') + '</span>' + ' ' + this.$t('pqrsOpened2'),
            focusConfirm: false,
            confirmButtonText:
              this.$t('ok'),
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i>',
            didOpen: () => {
              const goToPQRS = document.getElementById('goToPQRS')
              goToPQRS.addEventListener('click', () => {
                this.$swal.close()
                this.goToPQRS()
              })
            }
          })
        } else {
          this.setLoading(false)
          this.localLoading = false
        }
      }).catch(e => {
        console.log('ERROR LOADING PQRS -----> \n', e)
        // this.setLoading(false)
        this.localLoading = false
      })
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 17px;
  font-weight: bold;
}

.shortcut {
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: 65px;
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* background-color: red; */
  cursor: pointer;
}

.shortcut-link-container {
  width: 100%;
}

.shortcut > a {
  display: block !important;
  overflow: auto !important;
  line-height: 100%;
}
</style>
