<template>
  <SuiModal basic v-model="dialog">
    <SuiModalContent >
      <SuiModalDescription>

          <div class="row">
            <div class="col-md-8" style="padding-right: 0">
              <div class="modal-container">
                <a @click="close"  class="close-icon">
                  <i class="times icon general-blue-color"></i>
                </a>

                <div v-if="socialAreas.length === 0" class="align-items-center div-form p-4 text-center">
                  <span style="color: #646472">
                    {{$t('reservation.createSpace')}}
                    <br>
                    <router-link :to="{ path: '/residential/edition' }" class="space-link">{{$t('reservation.clickHere')}}</router-link>
                    {{$t('reservation.toCreate')}}
                  </span>
                </div>

                <div v-else class="align-items-center div-form">
                  <form
                      @submit.prevent="onSubmit"
                      novalidate
                      class="ui form fields"
                      :class="[{'blue double loading': loading}]"
                      autocomplete="off"
                    >
                      <h2 class="general-blue-color mb-1" style="margin-top: -15px; font-size: 17px">
                        {{ $t('reservation.manageReservationsForDay', { day }) }}
                      </h2>

                      <div class="mb-1 form-container">

                        <p style="margin-top: 29px">{{ $t('reservation.selectSpace') }}:</p>
                        <Select
                          v-model="socialAreaSelected"
                          :options="socialAreas"
                        />

                        <div
                          v-if="serviceHoursToShow.length > 0"
                          class="mt-3 mb-3"
                        >
                          <div class="row mt-2">
                            <div
                              class="col-md-12 text-left">
                              <span>{{ $t('reservation.youCanReserveThisSpace') }} </span>
                              <span
                                v-for="(item, index) in serviceHoursToShow"
                                :key="index"
                              >
                                <span v-if="index === 0">&nbsp;{{ $t('reservation.from') }} &nbsp;</span>
                                <span v-else>&nbsp;{{ $t('reservation.and') }} {{ $t('reservation.from') }}&nbsp;</span>
                                <span class="text-hour" v-if="index === 0 && item.from === item.to">&nbsp;{{ $t('reservation.24hours') }}&nbsp;</span>
                                <span v-else>
                                  <span class="text-hour"> {{ item.from }} </span>
                                  <span>&nbsp;{{ $t('reservation.to') }}&nbsp;</span>
                                  <span class="text-hour"> {{ item.to }} </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="socialAreaSelected !== '' && serviceHoursToShow.length === 0">
                          <div class="alert alert-secondary mt-4" role="alert">
                            {{ $t('reservation.noServiceForTheDay', { socialArea: socialAreaSelected.text }) }}
                          </div>
                        </div>

                        <div v-if="serviceHoursToShow.length > 0">
                          <div class="ui divider"></div>

                          <div class="mt-2" style="padding-left: 10px; padding-right: 20px">
                            <div class="form-check mt-2">
                              <input v-model="reservationFor" class="form-check-input" type="radio" id="flexRadioDefault1" value="1">
                              <label class="form-check-label" for="flexRadioDefault1">
                                {{ $t('reservation.reserveForMe') }}
                              </label>
                            </div>
                            <div class="mt-2">
                              <div class="form-check">
                                <input v-model="reservationFor" class="form-check-input" type="radio" id="flexRadioDefault2" value="2">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  {{ $t('reservation.reserveForOther') }}
                                </label>
                              </div>
                              <Select
                                v-if="reservationFor === '2'"
                                v-model="residentSelected"
                                :options="residents"
                                :search="true"
                                style="margin-left: 21px; margin-right: 50px"
                              />
                            </div>
                            <div class="form-check mt-2">
                              <input v-model="reservationFor" class="form-check-input" type="radio" id="flexRadioDefault3" value="3">
                              <label class="form-check-label" for="flexRadioDefault3">
                                {{ $t('reservation.makeUnderMaintenance') }} <i class="tools icon"></i>
                              </label>
                            </div>
                          </div>

                          <div class="mb-2">
                            <div class="mt-3 mb-2"><b>{{ $t('reservation.atWhatTimeToReserve') }}</b></div>
                            <span class="mx-4"> {{ $t('reservation.from') }} </span>
                            <SuiDropdown
                              style="margin-right: 5px"
                              selection
                              placeholder="--"
                              v-model="hourFrom"
                              :options="hours"
                            />
                            <SuiDropdown
                              selection
                              placeholder="--"
                              v-model="shiftFrom"
                              :options="['AM', 'PM']"
                            />
                            <span class="mx-4"> {{ $t('reservation.to') }} </span>
                            <SuiDropdown
                              style="margin-right: 5px"
                              selection
                              readonly
                              placeholder="--"
                              v-model="hourTo"
                              :options="hours"
                            />
                            <SuiDropdown
                              selection
                              placeholder="--"
                              v-model="shiftTo"
                              disabled
                              :options="['AM', 'PM']"
                            />
                          </div>

                          <!-- <div class="mt-4" v-if="showLockDays">
                            <span class="text-danger">*Este espacio podrá ser reservado por los próximos 30 días a partir del día de hoy.</span>
                          </div> -->

                        </div>
                      </div>

                      <div class="w-100 mt-0 text-center">
                        <div v-if="reservationId > 0">
                          <Button
                            color="third-color"
                            :text="$t('reservation.newReservation')"
                            class="d-inline"
                            :appearance="{ 'margin-right': '8px', height: '28px' }"
                            @clicked="clearForm" />

                          <Button
                            color="danger"
                            :text="$t('reservation.cancelReservation')"
                            class="d-inline"
                            :appearance="{ 'margin-right': '8px', height: '28px' }"
                            @clicked="delete_Reservation" />

                          <Button
                            color="primary"
                            :text="$t('reservation.modifyReservation')"
                            class="d-inline"
                            :appearance="{ height: '28px', 'margin-top': '10px' }"
                            @clicked="onSubmit" />
                        </div>

                        <Button
                          v-if="reservationId == 0 && serviceHoursToShow.length > 0"
                          color="primary"
                          :text="$t('reservation.reserve')"
                          class="d-inline"
                          @clicked="onSubmit" />
                      </div>

                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-3" style="padding-left: 0">
              <div class="list-reservations-container">
                <ReservationsDay
                  :date="dateReservation"
                  @clicked="editReservation"
                />
              </div>
            </div>
          </div>

      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription,
  SuiDropdown
} from 'vue-fomantic-ui'
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/form/Button'
import Select from '@/components/form/Select'
import { getSocialAreas } from '../../api/socialArea'
// deleteReservation
import { getReservation, createReservation, updateReservation, deleteReservation } from '../../api/reservation'
import ReservationsDay from '@/views/Reservation/ReservationsDay'
import helper from '@/utils/helpers'

export default {
  name: 'ReservationModal',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    SuiDropdown,
    Button,
    Select,
    ReservationsDay
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    date: {
      type: String,
      required: false,
      default: null
    },
    idReservationDefault: {
      type: Number,
      required: false,
      default: 0
    }
  },
  emits: ['close'],
  data () {
    return {
      loading: false,
      showDeleteConfirmation: false,
      // showLockDays: false,
      reservationId: 0,
      reservationFor: '0',
      serviceHours: [],
      socialAreas: [],
      socialAreaId: null,
      socialAreaSelected: '',
      residentSelected: null,
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      hourFrom: '',
      hourTo: '',
      shiftFrom: '',
      shiftTo: ''
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    },
    day () {
      if (this.date) {
        this.loadReservationsDate()
        let date = this.date
        if (this.date.length <= 10) {
          date = date + 'T00:00:00'
        }
        const dateFormat = new Date(date)
        this.loadHourFromByDefault(dateFormat)
        return `${this.$t(`days.${helper.getDayName(dateFormat.getDay())}`)},
                ${helper.formatDateDDMMYY(dateFormat)}`
      } else {
        return ''
      }
    },
    dateReservation () {
      return this.date
    },
    socialAreasToShow () {
      return this.socialAreas
    },
    serviceHoursToShow () {
      // console.log('serviceHoursToShow ---->', this.serviceHours)
      return this.serviceHours
    },
    residents () {
      const residents = this.$store.getters['user/residents']
      return residents.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(x => {
        const specs = `${x.building} - ${x.apartment}`
        return {
          id: x.id,
          description: `${x.firstName} ${x.lastName}, ${specs}`,
          avatar: helper.getAvatar(x.avatar)
        }
      })
    }
  },
  created () {
    this.loadSocialAreas()
    this.loadResidents()
  },
  updated () {
    this.loadSocialAreas()
    // console.log('active residential ', this.activeResidential)
    // console.log('We are going to create a reservations for the building ', this.activeResidential)
  },
  watch: {
    socialAreaSelected (val) {
      this.socialAreaId = Number(val.value)
      this.changeSocialArea()
    },
    async idReservationDefault (id) {
      const reservation = await getReservation(id)
      this.editReservation(reservation.data)
    },
    hourFrom () {
      if (this.reservationId === 0) {
        this.changeToHour()
      }
    },
    shiftFrom () {
      this.changeToHour()
    }
  },
  methods: {
    ...mapActions('reservation', ['cancelReservation']),

    ...mapActions('loading', ['setLoading']),
    loadSocialAreas () {
      // ESTO NO DEBERÍA HACERSE ACÁ, SINO EN EL STORE
      getSocialAreas(this.activeResidential.residentialId).then(({ data }) => {
        this.socialAreas = data.filter(y => y.status === this.status.ACTIVE).map(x => ({
          id: x.id,
          description: x.name,
          photoUrl: x.photoUrl ? x.photoUrl : undefined,
          icon: x.photoUrl ? undefined : x.socialAreaType.icon,
          serviceHours: x.serviceHours,
          maxHoursReservation: x.maxHoursReservation,
          lockReservationsUntilDays: x.lockReservationsUntilDays
        })).sort((a, b) => a.description.localeCompare(b.description))
      })
    },

    loadResidents () {
      if (this.residents.length === 0) {
        this.$store.dispatch('user/loadResidents', this.activeResidential.residentialId)
      }
    },

    loadHourFromByDefault (date) {
      if (date.getHours() > 0) {
        const hourFormat = helper.get12HoursTime(date)
        this.hourFrom = hourFormat.substr(0, 2)
        this.shiftFrom = hourFormat.substr(6, 2).toUpperCase()
      }
    },

    loadReservationsDate () {
      this.$store.dispatch('reservation/loadDateReservations', [
        { name: 'residentialId', value: this.activeResidential.residentialId },
        { name: 'initDate', value: this.date.substring(0, 10) },
        { name: 'endDate', value: this.date.substring(0, 10) }
      ]).then(() => {})
    },

    changeSocialArea () {
      const socialAreaId = this.socialAreaId
      if (socialAreaId > 0) {
        const socialArea = this.socialAreas.find(x => x.id === socialAreaId)
        const serviceHoursSocialArea = socialArea.serviceHours
        const date = this.date.substr(0, 10) + 'T00:00:00'
        const dayHours = helper.getDayName(new Date(date).getDay()).substr(0, 3)
        this.serviceHours = []
        if (serviceHoursSocialArea[dayHours]) {
          this.serviceHours = serviceHoursSocialArea[dayHours].map(x => {
            const time = x.split('-')
            return {
              from: this.getFullAMPMHour(time[0]),
              to: this.getFullAMPMHour(time[1])
            }
          })
        }

        // this.showLockDays = socialArea.lockReservationsUntilDays > 0
      }
    },

    changeToHour () {
      if (this.hourFrom !== '' && this.shiftFrom !== '') {
        if (this.hourFrom === '12') {
          this.hourTo = '01'
        } else {
          this.hourTo = (Number(this.hourFrom) + 1).toString().padStart('2', '0')
        }
        this.shiftTo = this.shiftFrom
      }
    },

    async delete_Reservation () {
      this.$swal({
        title: this.$t('reservation.sureToCancelReservation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteReservation(this.reservationId)
          this.cancelReservation({ id: this.reservationId })
          this.loadReservationsDate()
          this.clearForm()
          this.$swal(this.$t('reservation.cancelledReservation'), '', 'success')
        }
      })
    },

    async onSubmit () {
      if (this.validateReservation() === false) {
        return
      }
      const date = this.date.substr(0, 10)
      const data = {
        socialArea: this.socialAreaId,
        reservationStart: `${date} ${this.hourFrom}:00:00 ${this.shiftFrom}`,
        reservationEnd: `${date} ${this.hourTo}:00:00 ${this.shiftTo}`,
        underMaintenance: (this.reservationFor === '3')
      }
      if (this.reservationFor === '2') {
        data.user = this.residentSelected.value
      }
      this.setLoading(true)
      try {
        if (this.reservationId > 0) {
          const { data: response } = await updateReservation(this.reservationId, data)
          this.$swal(this.$t('reservation.modified'), '', 'success')
          this.loadReservationsDate()
          this.$store.dispatch('reservation/updateReservation', {
            id: response.id,
            title: ` ${response.socialArea.name} - ${response.user.firstName} ${response.user.lastName} (${response.user.apartment})`,
            start: new Date(response.reservationStart.replace('Z', '')),
            end: new Date(response.reservationEnd.replace('Z', '')),
            classNames: response.underMaintenance ? ['under-maintenance-label'] : ['reservation-label']

          })
        } else {
          const { data: response } = await createReservation(data)
          this.$store.dispatch('reservation/addReservations', [{
            id: response.id,
            // title: response.socialArea.name,
            title: ` ${response.socialArea.name} - ${response.user.firstName} ${response.user.lastName} (${response.user.apartment})`,
            start: new Date(response.reservationStart.replace('Z', '')),
            end: new Date(response.reservationEnd.replace('Z', '')),
            classNames: response.underMaintenance ? ['under-maintenance-label'] : ['reservation-label']

          }])
          // console.log({
          //   id: response.id,
          //   title: response.socialArea.name,
          //   start: new Date(response.reservationStart),
          //   end: new Date(response.reservationEnd),
          //   classNames: response.underMaintenance ? ['under-maintenance-label'] : ['reservation-label']
          // })
          this.$swal(this.$t('reservation.created'), '', 'success')
          this.close()
        }
      } catch (error) {
        console.error(error)
        this.manageErrorsOnCreate(error)
      }
      this.setLoading(false)
    },

    validateReservation () {
      if (!this.socialAreaId) {
        this.$swal(this.$t('errors.reservation.socialAreaNotSelected'), '', 'info')
        return false
      }

      const socialAreaSelected = this.socialAreas.find(sa => sa.id === this.socialAreaId)
      if (socialAreaSelected.lockReservationsUntilDays > 0) {
        const limitDay = new Date()
        limitDay.setDate(limitDay.getDate() + socialAreaSelected.lockReservationsUntilDays)
        const selectedDay = new Date(this.date)
        if (selectedDay > limitDay) {
          this.$swal(this.$t('reservation.cannotReserveOnDay'), '', 'warning')
          return false
        }
      }

      if (this.reservationFor === '0') {
        this.$swal(this.$t('errors.reservation.modeReservationNotSelected'), '', 'info')
        return false
      }
      if (this.reservationFor === '2') {
        if (!this.residentSelected.value) {
          this.$swal(this.$t('errors.reservation.residentNotSelected'), '', 'info')
          return false
        }
      }
      if (this.hourFrom === '' || this.shiftFrom === '') {
        this.$swal(this.$t('errors.reservation.hourNotSelected'), '', 'info')
        return false
      }

      if (this.reservationFor !== '3') {
        const { maxHoursReservation } = this.socialAreas.find(x => x.id === this.socialAreaId)
        const initDate = new Date(`${this.date.substr(0, 10)} ${this.hourFrom}:00 ${this.shiftFrom}`)
        const endDate = new Date(`${this.date.substr(0, 10)} ${this.hourTo}:00 ${this.shiftTo}`)

        const reservationDayInitials = helper.getDayName(initDate.getDay()).substr(0, 3)

        const MaxReservHoursForReservDay = maxHoursReservation[reservationDayInitials]

        if (helper.getHoursDiffDates(initDate, endDate) > MaxReservHoursForReservDay) {
          this.$swal(this.$t('errors.reservation.maxHoursExceedWithHours', { hours: MaxReservHoursForReservDay }), '', 'info')
          return false
        }
      }

      return true
    },

    manageErrorsOnCreate (error) {
      const responseCode = error.response.status
      if (responseCode === 400) {
        const errors = {
          '001': this.$t('errors.reservation.thisDayNotAllowed'),
          '002': this.$t('errors.reservation.initHourInvalid'),
          '003': this.$t('errors.reservation.endHourInvalid'),
          '004': this.$t('errors.reservation.hourInvalid'),
          '005': this.$t('errors.reservation.maxHoursExceed'),
          '006': this.$t('errors.reservation.maxPeople'),
          '007': this.$t('errors.reservation.userAlreadyHasReservation'),
          '008': this.$t('errors.reservation.socialAreaUnderMaintenance')
        }
        const errorText = errors[error.response.data.code] || this.$t('errors.tryLater')
        this.$swal(errorText, '', 'info')
      }
    },

    editReservation (reservation) {
      const {
        id,
        socialArea: {
          id: socialAreaId,
          name: text,
          photoUrl,
          icon
        },
        underMaintenance,
        user: { id: userId },
        createdBy,
        reservationEnd,
        reservationStart
      } = reservation
      const image = photoUrl ? { src: photoUrl } : undefined
      const label = !photoUrl ? { icon } : undefined
      this.socialAreaSelected = {
        value: socialAreaId, text, image, label
      }
      if (underMaintenance === true) {
        this.reservationFor = '3'
      } else if (userId !== createdBy) {
        this.reservationFor = '2'
        const { id, description, avatar } = this.residents.find(x => x.id === userId)
        this.residentSelected = {
          value: id,
          text: description,
          image: { src: avatar, avatar: true }
        }
      } else {
        this.reservationFor = '1'
      }
      this.reservationId = id

      const dateStart = new Date(reservationStart.replace('Z', ''))
      // console.log(new Date(reservationStart).toLocaleString())
      // const hourDateStart = Number(reservationStart.substr(11, 2))
      const hourFrom = dateStart.getHours() > 12 ? dateStart.getHours() - 12 : dateStart.getHours()
      this.hourFrom = hourFrom.toString().padStart(2, '0')
      this.shiftFrom = dateStart.getHours() > 12 ? 'PM' : 'AM'

      const dateEnd = new Date(reservationEnd.replace('Z', ''))
      // const hourDateEnd = Number(reservationEnd.substr(11, 2))
      const hourTo = dateEnd.getHours() > 12 ? dateEnd.getHours() - 12 : dateEnd.getHours()
      this.hourTo = hourTo.toString().padStart(2, '0')
      this.shiftTo = dateEnd.getHours() >= 12 ? 'PM' : 'AM'
    },

    clearForm () {
      this.reservationId = 0
      this.serviceHours = []
      this.socialAreaSelected = ''
      this.hourFrom = ''
      this.hourTo = ''
      this.shiftFrom = ''
      this.shiftTo = ''
      this.residentSelected = null
      this.reservationFor = '0'
      this.$store.dispatch('reservation/setActiveReservation', 0)
    },

    close () {
      this.clearForm()
      this.$emit('close')
    },

    capitalizeText (str) {
      return helper.capitalize(str.substr(0, 2))
    },

    getFullAMPMHour (hour) {
      hour = Number(hour)
      if (hour >= 12) {
        return `${hour > 12 ? hour - 12 : hour} pm`
      } else {
        return `${hour === 0 ? 12 : hour} am`
      }
    }
  }
}
</script>

<style>
  .ui.basic.modal {
    width: 70vw !important;;
  }
</style>

<style scoped>
  .modal-container {
    background-color: #fff;
    /* width: 655px; */
    height: 567px;
    border-radius: 20px;
    padding: 10px;
    /* margin-left: -338px; */
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
  }

  .list-reservations-container {
    position: absolute;
    /* right: -67px; */
    width: 293px;
    padding: 20px;
    background-color: white;
    color: black;
    margin-top: 50px;
    margin-bottom: 50px;
    /* margin-right: 115px; */
    height: 73%;
    border-radius: 0px 20px 20px 0px;
    overflow-y: auto;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
  }
  .form {
    color: #323232;
    margin-left: 10px;
    margin-right: 17px;
  }

  .text-hour {
    font-size: 20px;
    font-weight: bold;
  }

  .form-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 430px;
    padding-right: 10px
  }
  .ui.selection.dropdown {
    min-width: 5em;
  }

  .space-link {
    text-decoration: underline !important;
  }
</style>
